import { Checkbox } from '@material-ui/core';
import { Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { IndividualClient } from '../services/interactions';
import LabeledInput from './labeledInput';

const useStyles = makeStyles({
  header: {
    marginBottom: '1.25rem',
  },
  client: {
    marginBottom: '0.5rem',
    maxWidth: '25rem',
    paddingLeft: '2rem',
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'start',
  },
  editedClient: {
    height: 'inherit',
  },
  clientText: {
    flex: 1,
  },
});

function summary(client: IndividualClient): string[] {
  const lines: string[] = [];
  if (client.name) {
    lines.push(`Name ${client.name}`);
  }
  if (client.condition && client.condition.length > 0) {
    lines.push(`Condition ${client.condition.join(', ')}`);
  }
  if (client.community) {
    lines.push(`Community ${client.community}`);
  }
  if (client.followUp) {
    lines.push(`Follow up required`);
  }
  if (client.followUpTime) {
    lines.push(`Follow up time ${client.followUpTime}`);
  }
  if (client.followUpActions && client.followUpActions.length > 0) {
    lines.push(`Follow up actions ${client.followUpActions.join(', ')}`);
  }
  if (client.repeatCustomer) {
    lines.push(`Repeat client`);
  }
  if (client.caseNumber) {
    lines.push(`Case number ${client.caseNumber}`);
  }
  if (client.reasons && client.reasons.length > 0) {
    lines.push(`Reasons ${client.reasons.join(', ')}`);
  }
  return lines;
}

interface IndividualClientsProps {
  clients: IndividualClient[];
  selectedClients: number[];
  editClient: (clientIndex: number) => void;
  toggleClient: (clientIndex: number) => void;
}

export const IndividualClients: React.FC<IndividualClientsProps> = ({
  clients,
  selectedClients,
  editClient,
  toggleClient,
}) => {
  const classes = useStyles();
  const groups: Record<string, IndividualClient[]> = {};

  for (const client of clients) {
    const key = `${client.ageGroup} ${client.gender}`;
    groups[key] = groups[key] ? [...groups[key], client] : [client];
  }

  return (
    <>
      <Typography className={classes.header} variant="h6" component="h2">
        Individual Details
      </Typography>
      {Object.keys(groups).map(
        group =>
          groups[group].length > 0 && (
            <LabeledInput disableAnimation id={group} key={group} label={group}>
              {groups[group].map(client => {
                const changeLines = summary(client);

                return (
                  <Button
                    color={changeLines.length === 0 ? 'primary' : 'default'}
                    variant="contained"
                    className={`${classes.client} ${
                      changeLines.length === 0 ? '' : classes.editedClient
                    }`}
                    key={`${client.ageGroup}-${client.gender}-${client.index}`}
                  >
                    {/* TODO: This div and button need to change places */}
                    {/* We're breaking accessibility rules for a quick styling win */}
                    <div
                      onClick={(): void => editClient(client.index)}
                      className={classes.clientText}
                    >
                      <Typography>{`Client ${client.index + 1}`}</Typography>
                      {changeLines.map(x => (
                        <Typography variant="body2" key={x}>
                          {x}
                        </Typography>
                      ))}
                    </div>
                    {changeLines.length === 0 && (
                      <Checkbox
                        checked={selectedClients.includes(client.index)}
                        onChange={(): void => toggleClient(client.index)}
                      />
                    )}
                  </Button>
                );
              })}
            </LabeledInput>
          )
      )}
    </>
  );
};

export default IndividualClients;
