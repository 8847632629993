import React from 'react';
import { useAuthContext } from '../components/authContext';
import { useFormContext } from '../components/formContext';
import { FormActions, FormState, useNewForm } from '../forms/form';
import Layout from '../components/layout';
import useAuthRouteProtection from '../hooks/useAuthRouteProtection';
import useConfig from '../hooks/useConfig';
import { BaseProps } from '../typings/types';
import DateTime from '../components/dateTime';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import LoadingWrapper from '../components/loadingWrapper';
import IndividualClients from '../components/individualClients';
import FooterButton from '../components/footerButton';
import { useState } from 'react';
import { useEffect } from 'react';
import {
  FORM_TYPE_PAGE_URL,
  FORM_TYPE_TITLE,
  FormType,
} from '../services/lists';

interface PureEditClientProps {
  form?: FormState;
  dispatch: React.Dispatch<FormActions>;
}

export const PureEditClients: React.FC<PureEditClientProps> = ({
  form,
  dispatch,
}) => {
  return (
    <Layout
      navigateBackToPage={FORM_TYPE_PAGE_URL[form?.formType ?? FormType.Home]}
      title={form ? FORM_TYPE_TITLE[form.formType] : 'Loading...'}
    >
      <Grid alignContent="flex-start" justify="center" container>
        <LoadingWrapper
          loading={false}
          submitting={form?.submitting ?? false}
          hasConfig={true}
          submitted={false}
          error={form?.error ?? false}
        >
          {form && (
            <form
              data-testid="loaded"
              autoComplete="off"
              onSubmit={(e): void => {
                e.preventDefault();
                dispatch({ type: 'SUBMIT' });
              }}
            >
              <DateTime
                label="Pick up time"
                id="pick-up-time"
                name="pick-up-time"
                value={form.timeOccurred}
                onChange={(): void => {}}
              />
              <IndividualClients
                clients={form.individualClients}
                selectedClients={form.clientsToEdit}
                toggleClient={(client: number): void =>
                  dispatch({
                    type: 'CHANGE_CLIENTS_BEING_EDITED',
                    clientsToEdit:
                      form.clientsToEdit.indexOf(client) === -1
                        ? [...form.clientsToEdit, client]
                        : form.clientsToEdit.filter(x => x !== client),
                  })
                }
                editClient={(client: number): void =>
                  dispatch({
                    type: 'EDIT_INDIVIDUAL_CLIENT',
                    clientsToEdit: [client],
                  })
                }
              ></IndividualClients>
              {form.clientsToEdit.length > 0 && (
                <FooterButton
                  type="button"
                  onClick={(): void =>
                    dispatch({ type: 'EDIT_MULTIPLE_CLIENTS' })
                  }
                >
                  EDIT
                </FooterButton>
              )}
              <FooterButton type="submit">
                {form.submitting ? (
                  <>
                    <CircularProgress />
                    <Typography variant="srOnly">SUBMITTING</Typography>
                  </>
                ) : (
                  'SUBMIT'
                )}
              </FooterButton>
            </form>
          )}
        </LoadingWrapper>
      </Grid>
    </Layout>
  );
};

export const EditClients: React.FC<BaseProps> = ({ location }) => {
  const [loaded, setLoaded] = useState(false);
  const { getUser } = useAuthContext();
  const pathname = location.pathname;
  useAuthRouteProtection(pathname);
  const { form: existingForm } = useFormContext();
  const { config } = useConfig();
  const pageConfig =
    config && existingForm ? config.items[existingForm.formType] : undefined;
  const { dispatch, ...form } = useNewForm({
    getUser,
    formType: existingForm?.formType,
    config: pageConfig,
  });
  useEffect(() => {
    if (!loaded && form.initialised) {
      setLoaded(true);
      dispatch({ type: 'CHANGE_CLIENTS_BEING_EDITED', clientsToEdit: [] });
    }
  }, [loaded, dispatch, form]);
  return (
    <PureEditClients
      form={form.initialised ? form : existingForm}
      dispatch={dispatch}
    />
  );
};

export default EditClients;
